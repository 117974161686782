export default [
    {
        title: 'Components',
        icon: 'BoxIcon',
        children: [
            {
                title: 'Loading',
                route: 'loading',
            },
            {
                title: 'Button',
                route: 'button',
            },
            {
                title: 'Card',
                route: 'card',
            },
            {
                title: 'Form',
                route: 'form',
            }

        ],
    },
]
