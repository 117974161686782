export default [
    {
        title: 'Development',
        icon: 'CodeIcon',
        children: [
            {
                title: 'API',
                route: 'api',
            },
            {
                title: 'Routing',
                route: 'routing',
            },
            {
                title: 'Responsive Web',
                route: 'responsive-web',
            },
            {
                title: 'Pagination',
                route: 'pagination',
            },
            {
                title: 'Transition',
                route: 'transition',
            },
            {
                title: 'Layout',
                route: 'layout',
            },
            {
                title: 'Folder Structure',
                route: 'folder-structure',
            }
        ]
    }
]
