export default [
    {
        title: 'Projects',
        icon: 'BookIcon',
        children: [
            {
                title: 'enterBlock',
                icon: 'FolderIcon',
                children: [
                    {
                        title: 'Introduction',
                        icon: 'CircleIcon',
                        route: 'enter-introduction'
                    }
                ],
            },
            {
                title: 'Beat Someone Scan',
                icon: 'FolderIcon',
                children: [
                    {
                        title: 'Introduction',
                        icon: 'CircleIcon',
                        route: 'bso-introduction'
                    }
                ],
            }
        ]
    }
]